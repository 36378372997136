import React, { useState, useRef, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getFromLocalStorage, saveToLocalStorage } from 'staticData/common';
import { breakpoints } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoCarousel as textLang } from 'staticData/languages';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { trackEvent } from 'services/externals/mixpanel';

export const VideoItem = ({
  data,
  playSrc,
  setPlaySrc,
  showDescription = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const { id, src, title, time, poster, horizontal } = data;
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [programmaticUpdate, setProgrammaticUpdate] = useState(false);
  const overlayTimeout = useRef(null);

  const handlePlay = () => {
    if (!isSeeking)
      // track
      trackEvent('videoEvent', {
        status_event: 'play',
        video_id: id
      });
    setPlaySrc(src);
    setIsSeeking(false);
    setIsPaused(false);
    setIsEnded(false);
  };

  const handlePause = () => {
    if (!isSeeking) {
      setIsPaused(true);
      const actualLocalStorageVideos = getFromLocalStorage('videos') || {};
      saveToLocalStorage('videos', {
        ...actualLocalStorageVideos,
        [src]: videoRef.current.currentTime
      });
    }
  };

  const handleEnded = () => {
    setIsEnded(true);
  };

  const handleSeeking = () => {
    setIsSeeking(true);
  };

  const handleTimeUpdate = () => {
    setProgrammaticUpdate(false);
  };

  useEffect(() => {
    const lsVideos = getFromLocalStorage('videos') || {};
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      if (videoElement && lsVideos[src]) {
        if (lsVideos[src] >= videoElement.duration) setIsEnded(true);
        else {
          videoElement.currentTime = lsVideos[src] || 0;
          setProgrammaticUpdate(true);
          setIsPaused(true);
        }
      }
      setLoaded(true);
    };

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    // remove event listener on unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        );
      }
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      if (isPaused || isEnded) {
        overlayTimeout.current = setTimeout(() => {
          if (!isSeeking || programmaticUpdate) setShowOverlay(true);
          if (!isSeeking)
            // track
            trackEvent('videoEvent', {
              status_event: isEnded ? 'ended' : 'paused',
              video_id: id,
              video_time: videoRef.current.currentTime
            });
        }, 100);
      } else {
        clearTimeout(overlayTimeout.current);
        setShowOverlay(false);
      }
    }

    return () => {
      clearTimeout(overlayTimeout.current);
    };
  }, [isPaused, isEnded, isSeeking]);

  useEffect(() => {
    if (playSrc !== src) {
      videoRef.current.pause();
      handlePause();
    }
  }, [playSrc]);

  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <Flex justifyContent="center">
        <div
          style={{
            position: 'relative',
            width: 'fit-content',
            maxWidth: window.innerWidth < breakpoints['sm'] ? '350px' : 'none'
          }}
        >
          <video
            ref={videoRef}
            src={src}
            poster={loaded && !programmaticUpdate ? poster : null}
            controls
            height="450px"
            width={horizontal ? '254px' : ''}
            style={{ borderRadius: '10px' }}
            className={classNames('', {
              pointerCursor: isPaused || isEnded,
              'w-100': window.innerWidth < breakpoints['sm'] && !horizontal,
              'w-fit-content':
                window.innerWidth >= breakpoints['sm'] && !horizontal
            })}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onSeeking={handleSeeking}
            onTimeUpdate={handleTimeUpdate}
          />
          {showOverlay && (
            <div
              className="overlay"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '450px',
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${poster})`,
                backgroundSize: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '24px',
                borderRadius: '10px'
              }}
              onClick={() => videoRef.current.play()}
            >
              {isEnded ? (
                <div className="text-center">
                  <FontAwesomeIcon icon="check" className="me-1" />
                  <h4 style={{ margin: 0 }}>{textLang.alreadySeen[lang]}</h4>
                </div>
              ) : (
                <div className="text-center">
                  <FontAwesomeIcon icon="play" className="me-1" />
                  <h4 style={{ margin: 0 }}>{textLang.resume[lang]}</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </Flex>
      {showDescription && (
        <Row className="p-1">
          <Col className="mt-2">
            <h5>{title}</h5>
            <small></small>
            <p>
              {textLang.length[lang]} {time}
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

VideoItem.propTypes = {
  data: PropTypes.object.isRequired,
  playSrc: PropTypes.string,
  setPlaySrc: PropTypes.func,
  showDescription: PropTypes.bool
};

const VideoCarousel = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [playSrc, setPlaySrc] = useState(null);
  const isMobile = window.innerWidth < breakpoints['sm'];

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    xxl: { breakpoint: { max: 3000, min: breakpoints['xxl'] }, items: 4 },
    lg: {
      breakpoint: { max: breakpoints['xxl'], min: breakpoints['lg'] },
      items: 3
    },
    sm: {
      breakpoint: { max: breakpoints['lg'], min: breakpoints['sm'] },
      items: 2
    },
    xs: { breakpoint: { max: breakpoints['sm'], min: 0 }, items: 1 }
  };

  const videos = [
    {
      id: 'hodlie',
      src: `https://hodlie.ai/static/hodlie${isMobile ? '_small' : ''}.mp4`,
      title: textLang.hodlie[lang],
      time: '1:00',
      poster: 'video/poster/hodlie.png',
      horizontal: true
    },
    {
      id: 'kyc',
      src: `https://hodlie.ai/static/kyc${isMobile ? '_small' : ''}.mp4`,
      title: textLang.kyc[lang],
      time: '0:30',
      poster: 'video/poster/kyc.png'
    },
    {
      id: 'ai',
      src: `https://hodlie.ai/static/ai${isMobile ? '_small' : ''}.mp4`,
      title: textLang.ai[lang],
      time: '0:50',
      poster: 'video/poster/ai.png'
    },
    {
      id: 'binance',
      src: `https://hodlie.ai/static/binance${isMobile ? '_small' : ''}.mp4`,
      title: textLang.binance[lang],
      time: '0:50',
      poster: 'video/poster/binance.png'
    },
    {
      id: 'portfolio',
      src: `https://hodlie.ai/static/portfolio${isMobile ? '_small' : ''}.mp4`,
      title: textLang.portfolio[lang],
      time: '0:55',
      poster: 'video/poster/portfolio.png'
    },
    {
      id: 'un_anno',
      src: `https://hodlie.ai/static/un_anno${isMobile ? '_small' : ''}.mp4`,
      title: textLang.un_anno[lang],
      time: '1:20',
      poster: 'video/poster/un_anno.png'
    }
  ];

  return (
    <>
      <Row className="mt-2 p-2">
        <Col>
          <h4>{textLang.title[lang]}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel
            responsive={responsive}
            itemClass={classNames('', {
              'w-fit-content': window.innerWidth >= breakpoints['sm']
            })}
          >
            {videos.map((data, index) => (
              <div
                key={index}
                className={classNames('', {
                  'w-fit-content me-3': window.innerWidth >= breakpoints['sm'],
                  'd-flex justify-content-center':
                    window.innerWidth < breakpoints['sm']
                })}
              >
                <VideoItem
                  data={data}
                  playSrc={playSrc}
                  setPlaySrc={setPlaySrc}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default VideoCarousel;
