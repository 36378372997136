import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Row, Col, Alert } from 'react-bootstrap';
import { trackEvent } from 'services/externals/mixpanel';
import { Banner as textLang } from 'staticData/languages';

const BannerExpired = ({
  hasPortfolio = false,
  setShowUpToPro = () => null
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const handleclickBanner = () => {
    trackEvent('Banner Pro Expired Sub', {});
    setShowUpToPro(true);
  };

  return (
    <>
      <Row className="pointerCursor" onClick={handleclickBanner}>
        <Col>
          <Alert variant="success">
            <Alert.Heading>{textLang.expiredTitle[lang]}</Alert.Heading>
            <p className="">
              {textLang.expiredDescr[lang] +
                (hasPortfolio ? textLang.aiPort[lang] : '') +
                textLang.expiredDescr_1[lang]}
            </p>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

BannerExpired.propTypes = {
  hasPortfolio: PropTypes.bool,
  setShowUpToPro: PropTypes.func
};

export default BannerExpired;
