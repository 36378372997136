import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { /*Card,*/ Button } from 'react-bootstrap';
import { CreateBot as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import portfolioImg from 'assets/img/hodlie/portfolios.png';
// import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';

const ManifestCover = () => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    // <Card
    //   className="h-100 hover-card mb-3 py-4 pt-0 ps-0"
    //   as={Link}
    //   to={{ pathname: 'https://support.hodlie.ai/' }}
    //   target="_blank"
    //   style={{
    //     textDecoration: 'none'
    //   }}
    // >
    //   {/* <div
    //     style={{
    //       backgroundImage: `url(${portfolioImg})`,
    //       backgroundSize: '90% auto',
    //       backgroundPosition: '90% center',
    //       position: 'absolute',
    //       top: 0,
    //       right: 0,
    //       bottom: 0,
    //       left: 0,
    //       opacity: 0.3,
    //       zIndex: 0
    //     }}
    //   /> */}
    //   <Card.Header
    //     as="h4"
    //     style={{
    //       zIndex: 1
    //     }}
    //     className="pt-3 px-3"
    //   >
    //     {textLang.discoverGuide[lang]}
    //   </Card.Header>
    //   <Card.Body
    //     className="py-0"
    //     style={{
    //       zIndex: 1
    //     }}
    //   >
    //     <Button
    //       variant="falcon-primary"
    //       size="md"
    //       style={{
    //         textDecoration: 'none'
    //       }}
    //       as={Flex}
    //       className="w-fit-content align-items-center mt-3"
    //     >
    //       <p className="mb-0">{textLang.gotoguide[lang]}</p>
    //       <FontAwesomeIcon icon="chevron-right" className="ms-3" />
    //     </Button>
    //   </Card.Body>
    // </Card>
    <PageHeader
      as={Link}
      to={{ pathname: 'https://support.hodlie.ai/' }}
      target="_blank"
      style={{ textDecoration: 'none', color: 'inherit' }}
      title={textLang.discoverGuide[lang]}
      // description={textLang.manageText[lang]}
      className="mb-3 h-100"
    >
      <Button variant="link" size="sm" className="ps-0 mt-4">
        {textLang.gotoguide[lang]}
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button>
    </PageHeader>
  );
};

export default ManifestCover;
