import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GreetingCard as textLang } from 'staticData/languages';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';
import { getColor } from 'helpers/utils';
import 'assets/scss/custom/greatingCard.scss';
import { calculateCoinsValue } from 'services/coins/functions';
import { trackEvent } from 'services/externals/mixpanel';
import Lottie from 'lottie-react';
import halloween_pumpkin from 'assets/img/animated-icons/halloween_pumpkin.json';
import halloween_bat from 'assets/img/animated-icons/halloween_bat.json';
import halloween_ghost from 'assets/img/animated-icons/halloween_ghost.json';

const GreetingCard = ({
  username = '',
  showBody = false,
  generalWalletData = {},
  dynamicCoins = {},
  setAddCapitalModalShow,
  setSupportModalShow,
  onboardCompleted
}) => {
  const {
    config: { lang, currency },
    userInfos
  } = useContext(AppContext);
  const [showOthers, setShowOthers] = useState(false);
  const [generalWallet, setGeneralWallet] = useState(false);
  const [sideAnimationData, setSideAnimationData] = useState(null);
  const showAnimation = false;

  const getGreeting = () => {
    // get hour
    const now = new Date();
    const hour = now.getHours();
    let greeting = '';
    if (hour < 5) {
      greeting = textLang.greatingNight[lang];
    } else if (hour < 12) {
      greeting = textLang.greatingMorning[lang];
    } else if (hour < 17) {
      greeting = textLang.greatingAfternoon[lang];
    } else if (hour < 22) {
      greeting = textLang.greatingEvening[lang];
    } else {
      greeting = textLang.greatingNight[lang];
    }
    return greeting;
  };

  const dashboardButtonMap = {
    0: 'add',
    1: 'referral',
    2: 'support'
  };

  const handleTrackEvent = index => {
    trackEvent('Dashboard button', {
      event: dashboardButtonMap[String(index)]
    });
  };

  const activateModal = () => {
    setAddCapitalModalShow(true);
  };

  const greetingItems = [
    {
      id: 'add',
      icon: 'plus',
      color: 'primary',
      disabled: userInfos.pro_tier === 0,
      action: () => activateModal()
    },
    {
      id: 'referral',
      icon: 'gift',
      color: 'warning',
      to: '/rewards'
    },
    {
      id: 'feedback',
      icon: 'headset',
      color: 'info',
      action: () => setSupportModalShow(true)
    }
  ];

  const handleActions = (index, action) => {
    handleTrackEvent(index);
    if (action) action();
  };

  useEffect(() => {
    if (
      Object.keys(dynamicCoins).length &&
      Object.keys(generalWalletData).length
    ) {
      const localWallet = calculateCoinsValue(generalWalletData, dynamicCoins);
      setGeneralWallet(localWallet);
    }
  }, [dynamicCoins, Object.keys(generalWalletData).length]);

  useEffect(() => {
    const loadSideAnimation = () => {
      const animationArray = [
        halloween_pumpkin,
        halloween_bat,
        halloween_ghost
      ];
      setSideAnimationData(
        animationArray[Math.floor(Math.random() * animationArray.length)]
      );
    };

    loadSideAnimation();
  }, []);

  return (
    <Card className={'overflow-hidden'}>
      <Card.Header className="position-relative pb-0">
        <div
          className={classNames('position-relative z-index-2 pt-1 d-flex', {
            'pb-3 ps-3': onboardCompleted
          })}
          style={{ justifyContent: 'space-between' }}
          onClick={() => setShowOthers(!showOthers)}
        >
          <div
            className={classNames('align-items-center d-flex', {
              'd-flex':
                onboardCompleted ||
                (userInfos.pro_tier === 0 && !onboardCompleted)
            })}
          >
            {userInfos.pro_tier > 0 && onboardCompleted && (
              <h4 className="pe-3 mb-0 fs-0">Wallet</h4>
            )}
            <h3
              className="mb-0 d-none d-sm-block"
              dangerouslySetInnerHTML={{
                __html:
                  userInfos.pro_tier > 0 && onboardCompleted
                    ? `${
                        generalWallet != null
                          ? currency + Number(generalWallet).toFixed(2)
                          : ''
                      }`
                    : getGreeting() +
                      (username &&
                        `<font color='${getColor(
                          'primary'
                        )}'> ${username}</font>`)
              }}
            />
            <h5
              className="mb-0 d-sm-none"
              dangerouslySetInnerHTML={{
                __html:
                  userInfos.pro_tier > 0 && onboardCompleted
                    ? `${
                        generalWallet != null
                          ? currency + Number(generalWallet).toFixed(2)
                          : ''
                      }`
                    : getGreeting() +
                      (username &&
                        `<font color='${getColor(
                          'primary'
                        )}'> ${username}</font>`)
              }}
            />
            {sideAnimationData && showAnimation && (
              <Lottie
                animationData={sideAnimationData}
                style={{
                  width: '50px',
                  height: '50px'
                }}
              />
            )}
            <div>
              {onboardCompleted && (
                <FontAwesomeIcon
                  icon={`chevron-${showOthers ? 'up' : 'down'}`}
                  className="d-sm-none position-absolute end-0"
                  style={{
                    top: !showAnimation ? '20%' : '30%'
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Card.Header>
      {!onboardCompleted && (
        <Card.Body className="py-0">
          <p className="fs--1 text-800">{textLang.pickUpWhereYouLeft[lang]}</p>
        </Card.Body>
      )}
      {showBody && (
        <>
          <hr className="m-0" />
          <Card.Body
            className={classNames('z-index-1', {
              'd-none': window.innerWidth < breakpoints['sm'] && !showOthers
            })}
          >
            <Row className="g-2 h-100 align-items-start">
              {greetingItems.map(
                ({ icon, color, id, to, target, disabled, action }, index) => (
                  <Col
                    sm={4}
                    key={id}
                    as={Link}
                    to={{ pathname: to ? to : '' }}
                    target={target || ''}
                    className={classNames('p-2', { 'hover-card': !disabled })}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      opacity: disabled ? '.1' : '1'
                    }}
                    onClick={() =>
                      disabled ? null : handleActions(index, action)
                    }
                  >
                    <Flex className="position-relative">
                      <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                        <FontAwesomeIcon
                          icon={icon}
                          className={`text-${color}`}
                        />
                      </div>
                      <div className="flex-1">
                        <h6 className="mb-0">{textLang[id].title[lang]}</h6>
                        <p className="mb-0 fs--2 text-700">
                          {textLang[id].text[lang]}
                        </p>
                      </div>
                    </Flex>
                  </Col>
                )
              )}
            </Row>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

GreetingCard.propTypes = {
  username: PropTypes.string,
  showBody: PropTypes.bool,
  generalWalletData: PropTypes.object,
  dynamicCoins: PropTypes.object,
  setAddCapitalModalShow: PropTypes.func,
  setSupportModalShow: PropTypes.func,
  onboardCompleted: PropTypes.bool
};

export default GreetingCard;
