import React, { useContext } from 'react';
import { Accordion, Row } from 'react-bootstrap';
import FormTitle from 'pages/common/FormTitle';
import { FAQ as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const index = () => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const sectionsTitleMap = {
    pro: {
      'en-EN': 'Explorer and Investor Subscription',
      'it-IT': 'Abbonamento Explorer e Investor'
    },
    help: { 'en-EN': 'If I need help?', 'it-IT': 'Se ho bisogno di aiuto?' }
  };

  return (
    <>
      <Row md={12}>
        <FormTitle
          icon="question-circle"
          title="FAQ"
          subtitle={textLang.subtitle[lang]}
        />
      </Row>
      <Row className="mb-3 gy-3">
        <Accordion>
          {Object.entries(textLang.sections).map(
            ([section, content], index) => (
              <Accordion.Item
                eventKey={`${section}_${index.toString()}`}
                key={index}
              >
                <Accordion.Header>
                  <p style={{ margin: '0' }}>
                    {(sectionsTitleMap[section] &&
                      sectionsTitleMap[section][lang]) ||
                      section}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  {content.length ? (
                    content.map(
                      (singleFAQ, index) =>
                        singleFAQ.active && (
                          <Accordion key={index}>
                            <Accordion.Item
                              eventKey={`${singleFAQ}_${index.toString()}`}
                            >
                              <Accordion.Header>
                                <p
                                  style={{ margin: '0' }}
                                  dangerouslySetInnerHTML={{
                                    __html: singleFAQ.question[lang]
                                  }}
                                ></p>
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: singleFAQ.answer[lang]
                                  }}
                                ></p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )
                    )
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: content[lang]
                      }}
                    ></p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )
          )}
        </Accordion>
      </Row>
    </>
  );
};

export default index;
