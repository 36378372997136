import React, { useState, useEffect, useContext } from 'react';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { Col, Row } from 'react-bootstrap';
import LinePayment from 'pages/common/BotWallet/LinePayment';
import CoinTable from 'pages/common/BotWallet/CoinTable';
import NewsList from './NewsList';
import GreetingCard from '../GreetingCard';
import PortfolioRowData from 'pages/common/portfolioRow/PortfolioRowData';
import BotRow from 'pages/common/BotRow';
import NoPortfolio from './NoPortfolio';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';
import 'assets/scss/custom/realCard.scss';
import { currencyMap } from 'services/coins/common';
import AddCapitalModal from '../modals/addCapital';
import GeneralModal from '../modals/GeneralModal';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BotDashboard as textLang } from 'staticData/languages';
import { secondProTier, traderProTier } from 'staticData/common';
import { breakpoints, capitalize } from 'helpers/utils';
import { supportLinks, supportDirectContact } from 'staticData/support';
import { cleanCache } from 'services/cache';
import BlurChildren from 'pages/common/BlurChildren';
import BannerExpired from '../BannerExpired';
import PricingModal from 'pages/Pricing/modals';

const index = () => {
  const {
    config: { lang, currency },
    userInfos,
    coinValues
  } = useContext(AppContext);

  const showPortfolioData = true;

  const [loaded, setLoaded] = useState(false);
  const [bots, setBots] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [dynamicLoaded, setDynamicLoaded] = useState(false);
  const [wallet, setWallet] = useState({});
  const [generalWallet, setGeneralWallet] = useState();
  const [generalWalletData, setGeneralWalletData] = useState();
  const [dynamicBotMap, setDynamicBotMap] = useState();
  const [loadedLineChart, setLoadedLineChart] = useState(false);
  const [loadedCakeData, setLoadedCakeData] = useState(false);
  const [readyDynamicBot, setReadyDynamicBot] = useState(false);
  const [walletCoinMap, setWalletCoinMap] = useState();
  const [updateCoinValue, setUpdateCoinValue] = useState(true);
  const [dynamicCoins, setDynamicCoins] = useState({});
  const [addCapitalModalShow, setAddCapitalModalShow] = useState(false);
  const [addCapitalBotList, setAddCapitalBotList] = useState([]);
  const [generalModalShow, setGeneralModalShow] = useState(false);
  const [showUpToPro, setShowUpToPro] = useState(false);
  const exchange = 'binance';

  const getCurrentAndPreviousMinuteIsoStrings = () => {
    const now = new Date();
    const oneMinuteAgo = new Date(now);

    // Set current time to 00:00:00 UTC for both
    now.setUTCHours(0, 0, 0, 0);
    oneMinuteAgo.setUTCHours(0, 0, 0, 0);

    // Subtract one minute from the previous time
    oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);

    // Convert to ISO string and remove milliseconds
    const formatToIsoString = date =>
      date.toISOString().split('.')[0] + '+00:00';

    // Return array with one minute ago first, then now
    return [formatToIsoString(oneMinuteAgo), formatToIsoString(now)];
  };

  const hasPortfolioCategory = targetObj => {
    let type = null;

    // Convert the target object to an array of values and check if any value satisfies the required condition
    const found = Object.values(targetObj).some(innerObj => {
      if (
        innerObj.bot_details &&
        innerObj.bot_details?.category === 'portfolio'
      ) {
        type = innerObj.bot_details.type;
        return true;
      }
      return false;
    });

    var returnBg = null;
    if (found) {
      const { bgStyle } = portfolioList.find(
        portfolio => String(portfolio.id) === String(type)
      );
      returnBg = bgStyle;
    }

    return returnBg;
  };

  const getWalletStats = async () => {
    var params = { exchange: exchange, virtual: false };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getWallet',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      let data = walletStatsResponse.data;
      let localAddCapitalBotList = [];
      if (data) {
        localAddCapitalBotList = Object.keys(data)
          .filter(function (item) {
            return item != 'binance';
          })
          .map(key => {
            data[key].bot_details.exchange = 'binance';
            let obj = {
              coins: data[key].bot_details.assets,
              bot_details: data[key].bot_details,
              last_stat: data[key].last_stat
            };
            return obj;
          });
      }
      setAddCapitalBotList(localAddCapitalBotList);
      if (
        data[exchange]?.all_timepoints &&
        Object.keys(data[exchange]?.all_timepoints).length == 0
      ) {
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[0]
        ] = { usd_value: 0 };
        walletStatsResponse.data[exchange].all_timepoints[
          getCurrentAndPreviousMinuteIsoStrings()[1]
        ] = { usd_value: 0 };
        setUpdateCoinValue(false);
      }
      if (
        Object.keys(walletStatsResponse.data).includes('pending_bot') ||
        Object.values(walletStatsResponse.data).some(
          item => item.pending_change_allocation_task
        ) ||
        Object.values(walletStatsResponse.data).some(
          item => item?.pending_events?.length > 0
        )
      )
        setTimeout(async () => {
          await cleanCache(0);
        }, 3000);
      return walletStatsResponse.data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const sumObjects = objectsArray => {
    return objectsArray.reduce((accumulator, currentObject) => {
      Object.keys(currentObject).forEach(key => {
        if (key !== 'binance') {
          // if the key exists in the accumulator, add the value
          // if not, set the value to the value of the current object
          if (accumulator[key]) {
            accumulator[key] += currentObject[key];
          } else {
            accumulator[key] = currentObject[key];
          }
        }
      });
      return accumulator;
    }, {});
  };

  const processLiveData = (walletObj = wallet) => {
    var botLocalMap = {};
    var walletCoinsLocalMap = {};
    const aggregatedWallet = {};

    Object.entries(walletObj).map(([id, data]) => {
      let actualValue = null;
      let wallet = {};
      let assets = [];
      let botInitialAmount = 0;
      let sharpe = null;
      let coinSharpe = null;

      if (id === exchange) {
        // get last data in exchange timeseries
        if (Object.keys(data['4h_timepoints']).length > 0) {
          const newestDateKey = Object.keys(data['4h_timepoints']).reduce(
            (oldest, current) => {
              return new Date(current) > new Date(oldest) ? current : oldest;
            }
          );
          actualValue =
            data['4h_timepoints'][newestDateKey][currencyMap[currency]] || 0;
        }
      } else if (id !== 'pending_bot') {
        botInitialAmount =
          data.bot_details.initial_eqv[currencyMap[currency]] || 0;
        wallet = data.last_stat.wallet;
        assets = data.bot_details.assets;
        sharpe = data.indicators.sharpe;
        coinSharpe = data.indicators.coin_sharpe;
        // get data for otal wallet
        for (let [currency, amount] of Object.entries(wallet)) {
          if (aggregatedWallet[currency]) {
            aggregatedWallet[currency] += amount;
          } else {
            aggregatedWallet[currency] = amount;
          }
        }
      }

      botLocalMap[id] = {
        initialAmount: botInitialAmount,
        wallet: wallet,
        assets: assets,
        sharpe: sharpe,
        coinSharpe: coinSharpe,
        actualValue: actualValue
      };
    });
    // Convert wallet to coin values and keep track of the total value
    walletCoinsLocalMap[exchange] = Object.entries(aggregatedWallet).reduce(
      (acc, [currency, amount]) => {
        const currentPrice =
          dynamicCoins[currency] || coinValues.current[currency] || 0;
        const value = amount * currentPrice;
        // Add the current value to the aggregated map
        acc[currency] = value;
        // Accumulate the total value
        if (acc.total) {
          acc.total += value;
        } else {
          acc.total = value;
        }
        return acc;
      },
      {}
    );
    if (!dynamicBotMap || !walletCoinMap || updateCoinValue) {
      setWalletCoinMap(walletCoinsLocalMap);
      setDynamicBotMap(botLocalMap);
    }
    if (
      Object.keys(botLocalMap).includes(exchange) &&
      Object.keys(botLocalMap[exchange]).includes('actualValue')
    )
      setGeneralWallet(botLocalMap[exchange].actualValue || 0);

    // buildGeneral wallet data
    const walletContents = Object.values(botLocalMap)
      .map(item => item.wallet) // Estrai la proprietà wallet da ciascun oggetto
      .filter(wallet => wallet !== undefined);
    const localGeneralBotData = sumObjects(walletContents);
    setGeneralWalletData(localGeneralBotData);
    setReadyDynamicBot(true);
  };

  const convertWalletStats = walletObj => {
    const filteredAndMappedArray = Object.keys(walletObj)
      .filter(key => key !== exchange)
      .map(key => {
        const { bot_details, indicators, last_stat, snapshots, ...wallet } =
          walletObj[key];
        const added_capital = snapshots?.reduce(
          (sum, snapshot) =>
            sum + (snapshot.event_metadata.eqv[currencyMap[currency]] || 0),
          0
        );
        return {
          bot_details: bot_details,
          indicators: indicators,
          last_stat: last_stat,
          added_capital: added_capital,
          coins: bot_details.assets,
          wallet: wallet,
          snapshots: snapshots,
          isPending: bot_details.status === 'PENDING'
        };
      })
      .sort(
        (a, b) =>
          new Date(b.bot_details.created_at) -
          new Date(a.bot_details.created_at)
      );

    const bots = filteredAndMappedArray.filter(
      item => item.bot_details?.category === 'bot'
    );
    const portfolios = filteredAndMappedArray.filter(
      item => item.bot_details?.category === 'portfolio'
    );

    return {
      bots,
      portfolios
    };
  };

  const manageBotsData = async localWalletStat => {
    let localBotList = convertWalletStats(localWalletStat);
    setBots(localBotList.bots);
    setPortfolios(localBotList.portfolios);
  };

  useEffect(() => {
    let localWallet = {};
    const fetchData = async () => {
      try {
        localWallet = await getWalletStats();
        // If there is no data setLoadedLineChart only for tab enable
        let checkLineChart;
        if (
          !(
            Object.keys(localWallet).length > 0 &&
            localWallet[exchange] &&
            Object.keys(localWallet[exchange]).length > 0 &&
            Object.keys(localWallet[exchange]['all_timepoints']).length > 0
          )
        )
          checkLineChart = true;
        else checkLineChart = false;
        if (checkLineChart !== loadedLineChart)
          setLoadedLineChart(checkLineChart);
        // END If there is no data setLoadedLineChart only for tab enable
        setWallet(localWallet);
        manageBotsData(localWallet);
        setDynamicLoaded(true);
        processLiveData(localWallet);
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (coinValues.current) {
      setDynamicCoins(coinValues.current);
    }
    if (Object.keys(userInfos).length > 0 && !loaded) fetchData();
    else if (Object.keys(wallet).length > 0) {
      processLiveData();
    }
  }, [userInfos]);

  useEffect(async () => {
    var intervalCoinsData = setInterval(async () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
      }
    }, 500);

    return () => {
      clearInterval(intervalCoinsData);
    };
  }, []);

  const generalData = {
    head: (
      <Flex className="align-items-center">
        {textLang.title[lang]}
        <FontAwesomeIcon className="ms-2" icon="headset" />
      </Flex>
    ),
    body: (
      <>
        <p>{textLang.description[lang]}</p>
        <div className="mt-5 px-4">
          {userInfos.pro_tier >= secondProTier ? (
            <Link
              to={{ pathname: supportDirectContact.WA_LINK }}
              target="_blank"
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <h5
                className={classNames('mb-3', {
                  'border hover-card': userInfos.pro_tier >= secondProTier,
                  'd-flex align-items-center':
                    window.innerWidth > breakpoints['sm']
                })}
              >
                <span className="d-flex align-items-center">
                  <FontAwesomeIcon
                    className="me-4"
                    icon={['fab', 'whatsapp']}
                  />
                  {textLang.watsText[lang]}
                </span>
              </h5>
            </Link>
          ) : (
            userInfos.pro_tier > 0 && (
              <>
                <h5
                  className={classNames('mb-3', {
                    'border hover-card': userInfos.pro_tier >= secondProTier,
                    'd-flex align-items-center':
                      window.innerWidth > breakpoints['sm']
                  })}
                >
                  <span className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="me-4"
                      icon={['fab', 'whatsapp']}
                    />
                    {textLang.watsText[lang]}
                  </span>
                </h5>
              </>
            )
          )}

          <Link
            to={{
              pathname: `mailto:${
                userInfos.pro_tier === traderProTier ? 'private' : 'support'
              }@hodlie.net`
            }}
            target="_blank"
            style={{
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <h5 className="border d-flex align-items-center mb-3 hover-card">
              <FontAwesomeIcon className="me-4" icon="envelope" />
              {textLang.sendMail[lang]}
              {`${
                userInfos.pro_tier === traderProTier ? 'private' : 'support'
              }@hodlie.net`}
            </h5>
          </Link>
          <Link
            to={{
              pathname:
                userInfos.pro_tier >= secondProTier
                  ? supportLinks['INVESTOR_&_PRIVATE']
                  : supportLinks['EXPLORER']
            }}
            target="_blank"
            style={{
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <h5 className="border d-flex align-items-center mb-3 hover-card">
              <FontAwesomeIcon className="me-4" icon="calendar" />
              {textLang.videocall[lang]}
            </h5>
          </Link>
        </div>
      </>
    )
  };

  return (
    <>
      <AddCapitalModal
        addCapitalModalShow={addCapitalModalShow}
        setAddCapitalModalShow={setAddCapitalModalShow}
        addCapitalBotList={addCapitalBotList}
        dynamicCoins={dynamicCoins}
      />
      <GeneralModal
        showModal={generalModalShow}
        setShowModal={setGeneralModalShow}
        button={generalData.button}
        head={generalData.head}
        body={generalData.body}
      />
      {userInfos.pro_tier === 0 && (
        <PricingModal
          showModal={showUpToPro}
          setShowModal={setShowUpToPro}
          whatToDo={'newSub'}
          newProTier={0}
          type=""
        />
      )}
      <Row className={'mb-3'}>
        <Col xs={12}>
          <GreetingCard
            username={
              userInfos.kyc?.name
                ? capitalize(userInfos.kyc?.name)
                : userInfos.username
            }
            showBody
            generalWalletData={generalWalletData}
            dynamicCoins={dynamicCoins}
            setAddCapitalModalShow={setAddCapitalModalShow}
            setSupportModalShow={setGeneralModalShow}
            onboardCompleted={true}
          />
        </Col>
      </Row>
      {loaded && userInfos.pro_tier === 0 && (
        <BannerExpired
          hasPortfolio={portfolios.length > 0}
          setShowUpToPro={setShowUpToPro}
        />
      )}
      <Row className={classNames('', { 'mb-3': !bots.length })}>
        {showPortfolioData ? (
          <Col>
            {!loaded ? (
              <PortfolioRowData dynamicCoins={dynamicCoins} />
            ) : portfolios.length ? (
              portfolios.map((portfolio, index) => (
                <div
                  key={index}
                  className={classNames('', { 'mt-3': index > 0 })}
                >
                  {portfolio.isPending ? (
                    <NoPortfolio isPending={true} portfolioData={portfolio} />
                  ) : (
                    <BlurChildren useBlur={userInfos.pro_tier === 0}>
                      <PortfolioRowData
                        portfolioData={portfolio}
                        dynamicCoins={dynamicCoins}
                        botList={bots}
                      />
                    </BlurChildren>
                  )}
                </div>
              ))
            ) : (
              <NoPortfolio />
            )}
          </Col>
        ) : (
          <>
            <Col sm={8}>
              <LinePayment
                allWallet={wallet}
                dynamicBotMap={dynamicBotMap}
                generalWallet={generalWallet}
                h100={true}
                loaded={loadedLineChart}
                dynamicLoaded={dynamicLoaded}
                setLoaded={setLoadedLineChart}
                parentLoaded={loaded}
                simplifyChart={true}
                portfolioBgStyle={loaded ? hasPortfolioCategory(wallet) : null}
              />
            </Col>
            <Col sm={4}>
              <CoinTable
                dataReady={readyDynamicBot}
                walletCoinMap={walletCoinMap}
                loaded={loadedCakeData}
                setLoaded={setLoadedCakeData}
                parentLoaded={loaded}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="mb-3">
        <Col>
          {loaded &&
            bots.length > 0 &&
            bots.map((bot, index) => (
              <BlurChildren useBlur={userInfos.pro_tier === 0} key={index}>
                <BotRow bot={bot} dynamicCoins={dynamicCoins} />
              </BlurChildren>
            ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <NewsList />
        </Col>
      </Row>
    </>
  );
};

export default index;
