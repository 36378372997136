import React, { useContext } from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupportInfo as textLang } from 'staticData/languages';
import classNames from 'classnames';
import { supportLinks /*, supportDirectContact*/ } from 'staticData/support';
import 'assets/scss/custom/greatingCard.scss';
import { trackEvent } from 'services/externals/mixpanel';

const SupportInfo = () => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const dashboardButtonMap = {
    0: 'whatsapp',
    1: 'mail',
    2: 'videocall'
  };

  const handleTrackEvent = index => {
    trackEvent('Support button', {
      event: dashboardButtonMap[String(index)]
    });
  };

  const greetingItems = [
    // {
    //   id: 'WhatsApp',
    //   text: textLang.whatsAppText[lang],
    //   icon: ['fab', 'whatsapp'],
    //   color: 'primary',
    //   to: supportDirectContact.WA_LINK,
    //   target: '_blank'
    // },
    {
      id: 'Mail',
      text: textLang.mailText[lang],
      icon: 'envelope',
      color: 'warning',
      to: 'mailto:support@hodlie.net',
      target: '_blank'
    },
    {
      id: 'Videocall',
      text: textLang.videoCallText[lang],
      icon: 'calendar',
      color: 'info',
      to: supportLinks['USER'],
      target: '_blank'
    }
  ];

  const handleActions = (index, action) => {
    handleTrackEvent(index);
    if (action) action();
  };

  return (
    <div>
      <h4 className="pt-3 pb-2 px-3">
        <Flex className="align-items-center">
          {textLang.title[lang]}
          <FontAwesomeIcon className="ms-2" icon="headset" />
        </Flex>
      </h4>
      <Row className="h-100">
        {greetingItems.map(
          ({ icon, color, id, text, to, target, action }, index) => (
            <Col
              xs={12}
              key={id}
              as={Link}
              className={classNames('', {
                'mb-2': index < greetingItems.length - 1
              })}
              to={{ pathname: to ? to : '' }}
              target={target || ''}
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={() => handleActions(index, action)}
            >
              <Card className="hover-card p-0">
                <Card.Body>
                  <Flex className="position-relative">
                    <div className="icon-item icon-item-sm rounded-3 shadow-none me-2">
                      <FontAwesomeIcon
                        icon={icon}
                        className={`text-${color}`}
                        size="lg"
                      />
                    </div>
                    <div className="flex-1 ms-3">
                      <h6 className="mb-0">{id}</h6>
                      <p className="mb-0 text-700">{text}</p>
                    </div>
                  </Flex>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </div>
  );
};

export default SupportInfo;
