import React, { useContext, useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import PropTypes from 'prop-types';
import { Button, Card, Form, Row, Col, ProgressBar } from 'react-bootstrap';
import AppContext from 'context/Context';
import { ToDoList as textLang } from 'staticData/languages';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { breakpoints, getColor } from 'helpers/utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const ToDoListItem = ({ item, completed = false, action }) => {
  return (
    <Flex
      justifyContent="between"
      className={classNames(
        'hover-actions-trigger btn-reveal-trigger px-3 border-200 border-top',
        {
          'hover-bg-100 pointerCursor': action !== null
        }
      )}
      alignItems="center"
      onClick={action !== null ? () => action() : null}
    >
      <Row
        className="justify-content-between align-items-center"
        style={{ width: '-webkit-fill-available' }}
      >
        <Col xs={7}>
          <Form.Check className="py-2 my-2 d-flex align-items-center">
            <Form.Check.Input
              type="checkbox"
              checked={completed}
              style={
                item.customColor
                  ? {
                      borderColor: item.customColor,
                      backgroundColor: completed ? item.customColor : ''
                    }
                  : {}
              }
              className={classNames(`mt-0 rounded-circle`, {
                [`form-check-input-${item.color}`]: item.color,
                'form-check-line-through': completed,
                'pointerCursor ': action !== null
              })}
            />
            <Form.Check.Label
              className={classNames('flex-1 mb-0 ms-3', {
                'pointerCursor ': action !== null
              })}
            >
              {item.task}
            </Form.Check.Label>
          </Form.Check>
        </Col>
        {item.time && (
          <Col className="ps-0 justify-content-end" as={Flex}>
            <span
              className={classNames(`text-nowrap`, {
                'text-decoration-line-through text-500': completed
              })}
            >
              🕒 {item.time}
            </span>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

ToDoListItem.propTypes = {
  item: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  action: PropTypes.func.isRequired
};

const UserProgress = ({
  info,
  todoList,
  getNextAction,
  mobileVersion = false
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [showOthers, setShowOthers] = useState(false);
  const { action, task, description } = getNextAction(info);
  const nextId = getNextAction(info).id;
  const useMobileVersion =
    window.innerWidth < breakpoints['sm'] || mobileVersion;

  const getOptions = data => ({
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: '80%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor('primary')
          }
        },
        axisLine: {
          lineStyle: {
            width: 30,
            color: [[1, getColor('300')]],
            opacity: 0.5
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data,
            detail: {
              offsetCenter: ['7%', '4%']
            }
          }
        ],
        detail: {
          show: false
        }
      }
    ]
  });

  return (
    <Card className="h-100 bg-line-chart-gradient text-dark">
      <FalconCardHeader
        title={textLang.yourProgress[lang]}
        endEl={
          <span onClick={() => action()}>
            <FalconLink title={textLang.continue[lang]} className="px-0" />
          </span>
        }
      />
      <Row>
        <Col
          sm={6}
          className={classNames('d-none', { 'd-sm-block': !useMobileVersion })}
        >
          <div className="position-relative py-2">
            <ReactEChartsCore
              echarts={echarts}
              option={getOptions(info.level)}
              style={{ height: '25rem' }}
            />
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
                <b>{'Completato'}</b>
              </p>
              <p className="fs-6 mb-0 font-sans-serif fw-medium mt-n2 pt-2">
                {info.level}%
              </p>
            </div>
          </div>
        </Col>
        <Col sm={useMobileVersion ? 12 : 6}>
          <Card.Header>
            <h5>
              <span
                style={{
                  color:
                    todoList[info.completedNumber]?.customColor ||
                    getColor(todoList[info.completedNumber]?.color)
                }}
              >
                Step {info.completedNumber + 1}
              </span>
              : {task}
            </h5>
          </Card.Header>
          <Card.Body>
            <p
              className="mb-4 mb-sm-0"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            {!useMobileVersion && (
              <div className="">
                <ProgressBar
                  now={info.level}
                  key={1}
                  style={{ height: '20px' }}
                  className="rounded-pill mb-3"
                />
                <p className="mb-0 text-primary">
                  <b>
                    {info.level}% {textLang.completed[lang]}
                  </b>
                </p>
              </div>
            )}
          </Card.Body>

          {/* > MOBILE */}
          {(!useMobileVersion || showOthers) && (
            <Card.Body className="overflow-hidden">
              {todoList.map(item => (
                <ToDoListItem
                  key={item.id}
                  completed={info[item.id].value}
                  item={item}
                  action={item.id === nextId ? action : null}
                />
              ))}
            </Card.Body>
          )}

          {/* MOBILE */}
          {useMobileVersion && !showOthers && (
            <>
              <FalconCardHeader
                title={textLang.next[lang]}
                titleTag="h6"
                className="pb-0"
              />
              <Card.Body className="overflow-hidden">
                {todoList.map(
                  item =>
                    item.id === nextId && (
                      <ToDoListItem
                        key={item.id}
                        completed={info[item.id].value}
                        item={item}
                        action={item.id === nextId ? action : null}
                      />
                    )
                )}
              </Card.Body>
            </>
          )}
        </Col>
      </Row>

      {!mobileVersion && (
        <Card.Footer className="p-0 bg-light">
          {window.innerWidth < breakpoints['sm'] ? (
            <Button
              variant="link"
              size="sm"
              className="w-100 py-2"
              onClick={() => setShowOthers(!showOthers)}
            >
              {showOthers ? textLang.hide[lang] : textLang.more[lang]}
              <FontAwesomeIcon
                icon={`chevron-${showOthers ? 'up' : 'down'}`}
                className="ms-1 fs--2"
              />
            </Button>
          ) : (
            <Button
              onClick={() => action()}
              variant="link"
              size="sm"
              to="#!"
              className="w-100 py-2"
            >
              {textLang.continue[lang]}
              <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

UserProgress.propTypes = {
  info: PropTypes.object.isRequired,
  todoList: PropTypes.array.isRequired,
  getNextAction: PropTypes.func.isRequired,
  mobileVersion: PropTypes.bool
};

export default UserProgress;
